// 默认路由
const defaultRouter = {
  path: 'home',
  name: 'general.home',
  component: () => import('@/views/general/home/index.vue'),
  meta: {
    title: '首页',
    auth: {
      required: true,
      header: false,
      list: [],
    },
  },
};
export default {
  default: defaultRouter,
  route: [
    defaultRouter,
    {
      path: 'supplier',
      name: 'general.supplier',
      component: () => import('@/views/general/supplier/index.vue'),
      meta: {
        title: '供应商管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'scenic',
      name: 'general.scenic',
      component: () => import('@/views/general/scenic/index.vue'),
      meta: {
        // title: "景区管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'hotel',
      name: 'general.hotel',
      component: () => import('@/views/general/hotel/index.vue'),
      meta: {
        title: '酒店管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'food',
      name: 'general.food',
      component: () => import('@/views/general/food/index.vue'),
      meta: {
        title: '餐饮门店管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'specialty',
      name: 'general.specialty',
      component: () => import('@/views/general/specialty/index.vue'),
      meta: {
        title: '购物店管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'motorcade',
      name: 'general.motorcade',
      component: () => import('@/views/general/motorcade/index.vue'),
      meta: {
        title: '车队管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'distributor',
      name: 'general.distributor',
      component: () => import('@/views/general/distributor/index.vue'),
      meta: {
        title: '分销商管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'admin',
      name: 'general.admin',
      component: () => import('@/views/general/admin/index.vue'),
      meta: {
        title: '管理员管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'menuTree',
      name: 'general.menuTree',
      component: () => import('@/views/general/menuTree/index.vue'),
      meta: {
        title: '菜单树管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'journal',
      name: 'general.journal',
      component: () => import('@/views/general/journal/index.vue'),
      meta: {
        title: '操作日志',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'loginLog',
      name: 'general.loginLog',
      component: () => import('@/views/general/loginLog/index.vue'),
      meta: {
        title: '登录日志',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'roleGroup',
      name: 'general.roleGroup',
      component: () => import('@/views/general/roleGroup/index.vue'),
      meta: {
        title: '角色组管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'supplierAudit',
      name: 'general.supplierAudit',
      component: () => import('@/views/general/supplierAudit/index.vue'),
      meta: {
        title: '供应商审核',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'distributorAudit',
      name: 'general.distributorAudit',
      component: () => import('@/views/general/distributorAudit/index.vue'),
      meta: {
        title: '分销商审核',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'checkPending',
      name: 'general.checkPending',
      component: () => import('@/views/general/checkPending/index.vue'),
      meta: {
        title: '待审核列表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'corpCheck',
      name: 'general.corpCheck',
      component: () => import('@/views/general/corpCheck/index.vue'),
      meta: {
        title: '企业信息变更申请',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'ticketDocking',
      name: 'general.ticketDocking',
      component: () => import('@/views/general/ticketDocking/index.vue'),
      meta: {
        title: '票务系统对接',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'productsTicket',
      name: 'general.productsTicket',
      component: () => import('@/views/general/productsTicket/index.vue'),
      meta: {
        title: '门票产品管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'productsCombination',
      name: 'general.productsCombination',
      component: () => import('@/views/general/productsCombination/index.vue'),
      meta: {
        title: '组合商品管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'productsFleet',
      name: 'general.productsFleet',
      component: () => import('@/views/general/productsFleet/index.vue'),
      meta: {
        title: '车队管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'productsHotel',
      name: 'general.productsHotel',
      component: () => import('@/views/general/productsHotel/index.vue'),
      meta: {
        title: '酒店管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'productsLine',
      name: 'general.productsLine',
      component: () => import('@/views/general/productsLine/index.vue'),
      meta: {
        title: '线路管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'productsRestaurant',
      name: 'general.productsRestaurant',
      component: () => import('@/views/general/productsRestaurant/index.vue'),
      meta: {
        title: '餐饮管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'productsSpecialty',
      name: 'general.productsSpecialty',
      component: () => import('@/views/general/productsSpecialty/index.vue'),
      meta: {
        title: '特产管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'refundTicket',
      name: 'general.refundTicket',
      component: () => import('@/views/general/refundTicket/index.vue'),
      meta: {
        title: '退票列表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'checkPendingRefundTicket',
      name: 'general.checkPendingRefundTicket',
      component: () => import('@/views/general/checkPendingRefundTicket/index.vue'),
      meta: {
        title: '待审核退票列表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'soldOrder',
      name: 'general.soldOrder',
      component: () => import('@/views/general/soldOrder/index.vue'),
      meta: {
        title: '已售订单列表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'creditAccount',
      name: 'general.creditAccount',
      component: () => import('@/views/general/creditAccount/index.vue'),
      meta: {
        title: '备用金账户信息',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'creditAccountRecord',
      name: 'general.creditAccountRecord',
      component: () => import('@/views/general/creditAccountRecord/index.vue'),
      meta: {
        title: '备用金资金记录',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'supplierAccount',
      name: 'general.supplierAccount',
      component: () => import('@/views/general/supplierAccount/index.vue'),
      meta: {
        title: '平台账户信息',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'supplierMoneyRecord',
      name: 'general.supplierMoneyRecord',
      component: () => import('@/views/general/supplierMoneyRecord/index.vue'),
      meta: {
        title: '平台资金记录',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'alreadyMessage',
      name: 'general.alreadyMessage',
      component: () => import('@/views/general/alreadyMessage/index.vue'),
      meta: {
        title: '已发消息',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'broadcastMessage',
      name: 'general.broadcastMessage',
      component: () => import('@/views/general/broadcastMessage/index.vue'),
      meta: {
        title: '广播消息',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'msgTemplate',
      name: 'general.msgTemplate',
      component: () => import('@/views/general/msgTemplate/index.vue'),
      meta: {
        title: '消息组管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'distributorDocking',
      name: 'general.distributorDocking',
      component: () => import('@/views/general/distributorDocking/index.vue'),
      meta: {
        title: '分销商对接',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'receivedMessage',
      name: 'general.receivedMessage',
      component: () => import('@/views/general/receivedMessage/index.vue'),
      meta: {
        title: '已收消息',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'foodScenic',
      name: 'general.foodScenic',
      component: () => import('@/views/general/foodScenic/index.vue'),
      meta: {
        title: '餐饮门店管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    //停车场
    {
      path: 'carPark',
      name: 'general.carPark',
      component: () => import('@/views/general/carPark/index.vue'),
      meta: {
        title: '停车厂管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'monthlyCarRental',
      name: 'general.monthlyCarRental',
      component: () => import('@/views/general/monthlyCarRental/index.vue'),
      meta: {
        title: '月租车管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'reserveCar',
      name: 'general.reserveCar',
      component: () => import('@/views/general/reserveCar/index.vue'),
      meta: {
        title: '预约车管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'recordLog',
      name: 'general.recordLog',
      component: () => import('@/views/general/recordLog/index.vue'),
      meta: {
        title: '出入记录',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'parkingStopLog',
      name: 'general.parkingStopLog',
      component: () => import('@/views/general/parkingStopLog/index.vue'),
      meta: {
        title: '停车记录转发',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: 'freeIntoGardenLog',
      name: 'general.freeIntoGardenLog',
      component: () => import('@/views/general/freeIntoGardenLog/index.vue'),
      meta: {
        title: '免费入园记录',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'comment',
      name: 'general.comment',
      component: () => import('@/views/general/comment/index.vue'),
      meta: {
        title: '评论列表',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'componentManage',
      name: 'general.componentManage',
      component: () => import('@/views/general/componentManage/index.vue'),
      meta: {
        title: '评论管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: 'shopMange',
      name: 'general.shopMange',
      component: () => import('@/views/general/shopMange/index.vue'),
      meta: {
        title: '购物店门店管理',
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
  ],
};
